<template>
  <div class="home">
    <!--Top section-->
    <section>
      <topSection :isSliderOn="false" />
    </section>
    <!--Projects-->
    <section>
      <projectsKlima />
    </section>
    <!--Footer-->
    <footerr>
      <footer />
    </footerr>
  </div>
</template>

<script>
import topSection from '@/components/home/TopSection.vue';
import projectsKlima from '@/components/klima/ProjectsKlima.vue';
import footerr from '@/components/home/Footer.vue';

export default {
  components: {
    topSection,
    projectsKlima,
    footerr,
  },
};
</script>

<style scoped></style>
