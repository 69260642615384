<template>
  <div class="mt-3">
    <div class="container p-4">
      <div class="row bdy">
        <div class="mb-4 col-lg-6 col-md-6 mb-md-0">
          <div class="mx-auto">
            <!-- Swiper -->
            <Carousel3d>
              <Slide :index="0">
                <img src="../../assets/klima/2.jpeg" alt="klima" />
              </Slide>

              <Slide :index="1">
                <img src="../../assets/klima/3.jpeg" alt="klima" />
              </Slide>
            </Carousel3d>
          </div>
        </div>
        <div class="mb-4 col-lg-6 col-md-6 mb-md-0">
          <h2>Klima işlerimiz</h2>

          <p>
            NÖBETÇİ TEKNİK SERVİS ANTALYA"NIN HER YERİNDE EVİNİZİN VE İŞ YERİNİZİN HER NEVİ TEKNİK
            İHTİYACI 7/24 KESİNTİSİZ HİZMET.........!
          </p>
        </div>
      </div>
    </div>
    <div class="underline"></div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style>
.bdy {
  margin-top: 150px;
  margin-bottom: 100px;
}
.underline {
  height: 15px;
  margin-top: 2px;
  background-color: #ed1c24;
}
</style>
